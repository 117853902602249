import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ErrorBoundary from 'components/ErrorHandler'
import 'core-js/features/array/at'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useState } from 'react'
import { Toaster } from 'ui'
import 'ui/styles/global.css'
import '../styles/globals.css'
import { IS_DEV } from '../config/config.exporter'
import { createPortal } from 'react-dom'

//dumb comment to trigger deploy
function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <>
      <ErrorBoundary>
        <Head>
          <meta charSet='utf-8' />
          <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
          <meta
            name='viewport'
            content='width=device-fwidth,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
          />
          <meta name='description' content='Autoinspector' />

          {!IS_DEV && (
            <script
              dangerouslySetInnerHTML={{
                __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3322418,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
              }}
            />
          )}

          <link rel='icon' href='/assets/icon-192x192.png?v=2' />
        </Head>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
          {typeof window !== 'undefined' &&
            createPortal(
              <div className='z-[99999]'>
                <Toaster size='lg' />
              </div>,
              document.body,
            )}
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  )
}

export default MyApp
